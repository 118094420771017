import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';

export enum SideMenuCategory {
    TREE_CATEGORY_SEARCH = 'search',
    TREE_CATEGORY_ROUTE = 'route',
}

export interface SideMenuState {
    isSideMenuOpen: boolean;
    currentCategory: SideMenuCategory;
}

const initialState: SideMenuState = {
    isSideMenuOpen: false,
    currentCategory: SideMenuCategory.TREE_CATEGORY_ROUTE,
};

const sideMenuSlice = createSlice({
    name: STORE_BASE_PATH + 'sideMenu',
    initialState,
    reducers: {
        toggleSideMenu: (state) => {
            state.isSideMenuOpen = !state.isSideMenuOpen;
        },
        changeCategory: (state, action: PayloadAction<SideMenuCategory>) => {
            state.currentCategory = action.payload;
        },
    },
});

export const { toggleSideMenu, changeCategory } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
