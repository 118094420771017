import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { SmartRouteWebApi, STORE_BASE_PATH } from '../../common/constants';
import { SubscriptionLevel, SubscriptionState } from '../../common/types';
import { config } from '../../configuration/config';
import { prepareHeaders } from '../tokenHandling/prepareHeaders';

// eslint-disable-next-line max-len
const PERMITTED_ASSETS_URL = `${config.backend.BACKEND_DOMAIN}/${SmartRouteWebApi.serviceName}/${SmartRouteWebApi.apiVersion.latest}`;

export interface PermittedAssetsModel {
    items: {
        id: string;
        name: string;
        type: string;
        identification: string;
        identification_type: string;
        subscription_state: {
            status: SubscriptionState;
            subscription: SubscriptionLevel;
        };
        group_ids: string[];
    }[];
}

export const permittedAssetsApi = createApi({
    reducerPath: `${STORE_BASE_PATH}permittedAssetsApi`,
    baseQuery: fetchBaseQuery({ baseUrl: PERMITTED_ASSETS_URL, prepareHeaders }),
    endpoints: (builder) => ({
        getPermittedAssets: builder.query<PermittedAssetsModel, void>({
            query: (): FetchArgs => ({
                url: `${SmartRouteWebApi.endpoints.permittedAssets}`,
                responseHandler: 'json',
            }),
        }),
    }),
});

export const { useGetPermittedAssetsQuery, useLazyGetPermittedAssetsQuery } = permittedAssetsApi;
