import { trace } from './trace';

const localStorage = window.localStorage;
const STORAGE_PREFIX = 'livemonitor.';

const save = (key: string, value: string | boolean, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
        return value;
    } catch (_) {
        // Intentionally left blank
    }
};

const removeItem = (key: string, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.removeItem(`${prefix}${key}`);
    } catch (_) {
        // Intentionally left blank
    }
};

const load = (key: string, prefix: string = STORAGE_PREFIX): string | undefined => {
    try {
        const item = localStorage.getItem(`${prefix}${key}`);
        return item && JSON.parse(item);
    } catch (_) {
        // Intentionally left blank
    }
};

export const storage = { save, load, removeItem };

const supportsLocalStorage = (window: Window) => {
    try {
        const key = 'RIO_SUPPORTS_LOCAL_STORAGE';
        window.localStorage.setItem(key, 'true');
        const isSupported = window.localStorage.getItem(key);
        window.localStorage.removeItem(key);
        return isSupported;
    } catch (_) {
        trace.warn('[feature/login] localStorage not supported!');
        return false;
    }
};

export const configureStorage = (window: Window) => {
    if (supportsLocalStorage(window)) {
        const routeKey = 'oauth_initial_route';
        return {
            discardRoute: () => localStorage.removeItem(routeKey),
            getRoute: () => localStorage.getItem(routeKey),
            saveRoute: (route: string) => localStorage.setItem(routeKey, route),
        };
    }

    trace.warn('[feature/login] LocalStorage for saving "oauth_state" not available!');

    // This is bad, should we proceed and how?
    return {
        discardRoute: () => {},
        getRoute: () => '/',
        saveRoute: () => {},
    };
};

export const routeStorage = configureStorage(window ?? {});
