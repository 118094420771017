import { config } from '../configuration/config';

export const MODULE_NAME = 'SmartRoute';
export const DEFAULT_ROUTE = '/';
export const STORE_BASE_PATH = `${config.serviceName}/`;

export const HereSettings = {
    apikey: config.backend.MAP_SERVICE_API_KEY,
};

export const MarketplaceSettings = {
    URL: config.backend.MARKETPLACE_SERVICE,
    productId: 'man-sku00000085',
    defaultLevel: '10',
};

export const SmartRouteWebApi = {
    serviceName: 'smart-route-web-api',
    apiVersion: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        accountSubscription: '/subscription/account',
        permittedAssets: '/permitted-assets',
        accountGroups: '/tags/groups',
    },
};
