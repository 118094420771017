import { createSlice } from '@reduxjs/toolkit';
import mapValues from 'lodash/fp/mapValues';

import { STORE_BASE_PATH } from '../../common/constants';
import { storage } from '../../configuration/setup/storage';

export interface OnboardingState {
    showTableSettingsTip: boolean;
    showQuickFilter: boolean;
    showTree: boolean;
    showShareLinkTip: boolean;
}

const defaultOnboardingState = {
    showTableSettingsTip: true,
    showQuickFilter: true,
    showTree: true,
    showShareLinkTip: true,
};

const initialState: OnboardingState = {
    ...defaultOnboardingState,
};

export const onboardingSlice = createSlice({
    name: STORE_BASE_PATH + 'onboarding',
    initialState,
    reducers: {
        onboardingToggled: (state, action) => {
            const onboarding = {
                ...state,
                [action.payload.tip]: action.payload.toggle,
            };

            storage.save('onboarding', onboarding);
            return {
                ...state,
                ...onboarding,
            };
        },
        onboardingResetted: (state, action) => {
            const onboarding = action.payload ? defaultOnboardingState : mapValues(() => false)(defaultOnboardingState);

            return {
                ...onboarding,
            };
        },
    },
});

export const { onboardingLoadDefault, onboardingToggled, onboardingResetted } = onboardingSlice.actions;

export default onboardingSlice.reducer;
