import { lazy } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';

import { SideMenu } from '../../components/SideMenu/SideMenu';
import { MainContainer } from '../MainContainer/MainContainer';
import { AppLayoutHeader } from '../AppLayoutHeader/AppLayoutHeader';
import { IntlProvider } from 'react-intl';
import { useAppSelector } from '../../configuration/setup/hooks';
import { selectDisplayMessages, selectLocale } from '../../store/lang/langSelectors';
import { config } from '../../configuration/config';

export const AppLayout = () => {
    const userLocale = useAppSelector(selectLocale);
    const displayMessages = useAppSelector(selectDisplayMessages);

    return (
        <IntlProvider
            defaultLocale={config.login.defaultLocale}
            key={userLocale}
            locale={userLocale}
            messages={displayMessages}
        >
            <ApplicationLayout>
                <ApplicationLayout.Header>
                    <AppLayoutHeader />
                </ApplicationLayout.Header>

                <ApplicationLayout.Body innerClassName="padding-0">
                    <ApplicationLayout.Sidebar>
                        <SideMenu />
                    </ApplicationLayout.Sidebar>

                    <MainContainer />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};
