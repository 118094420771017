import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../configuration/setup/hooks';
import { store } from '../../configuration/setup/store';
import { chapters } from '../../pages/RoutePage/chapters';
import { selectAreOnboardingTipsShown } from '../../store/onboarding/onboardingSelectors';
import { onboardingResetted } from '../../store/onboarding/onboardingSlice';
import { serviceInfoDialogOpenedAtChapter } from '../../store/serviceInfo/serviceInfoSlice';

export const ServiceInfoPopover = () => {
    const areTipsShown = useAppSelector(selectAreOnboardingTipsShown);

    const handleOpenChapter = (chapter) => {
        store.dispatch(serviceInfoDialogOpenedAtChapter(chapter));
    };

    const handleResetOnboarding = (shouldReset) => store.dispatch(onboardingResetted(shouldReset));

    return (
        <ActionBarItem.List className="ServiceInfoPopoverContent">
            <div data-testid="serviceinfo.welcome">
                <ActionBarItem.ListItem icon="rioglyph-hand-right" onClick={() => handleOpenChapter(chapters.welcome)}>
                    <FormattedMessage id="intl-msg:smartRoute.generic.welcome" />
                </ActionBarItem.ListItem>
            </div>

            <div data-testid="serviceinfo.whatsNew">
                <ActionBarItem.ListItem
                    icon="rioglyph-exclamation-sign"
                    onClick={() => handleOpenChapter(chapters.whatsNew)}
                >
                    <FormattedMessage id="intl-msg:smartRoute.generic.whatsNew" />
                </ActionBarItem.ListItem>
            </div>

            <div data-testid="serviceinfo.features">
                <ActionBarItem.ListItem
                    icon="rioglyph-question-sign"
                    onClick={() => handleOpenChapter(chapters.features.mapAndListView)}
                >
                    <FormattedMessage id="intl-msg:smartRoute.serviceinfo.functions" />
                </ActionBarItem.ListItem>
            </div>

            <div data-testid="serviceinfo.feedback">
                <ActionBarItem.ListItem icon="rioglyph-envelope" onClick={() => handleOpenChapter(chapters.feedback)}>
                    <FormattedMessage id="intl-msg:smartRoute.generic.feedback" />
                </ActionBarItem.ListItem>
            </div>

            <div data-testid="serviceinfo.onboardings">
                <ActionBarItem.ListItem
                    icon="rioglyph-question-sign"
                    onClick={() => handleResetOnboarding(!areTipsShown)}
                >
                    {areTipsShown ? (
                        <FormattedMessage id="intl-msg:smartRoute.generic.hideTips" />
                    ) : (
                        <FormattedMessage id="intl-msg:smartRoute.generic.showtips" />
                    )}
                </ActionBarItem.ListItem>
            </div>
        </ActionBarItem.List>
    );
};
