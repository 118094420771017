import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { SmartRouteWebApi, STORE_BASE_PATH } from '../../common/constants';
import { config } from '../../configuration/config';
import { prepareHeaders } from '../tokenHandling/prepareHeaders';

// eslint-disable-next-line max-len
const ACCOUNT_GROUPS_URL = `${config.backend.BACKEND_DOMAIN}/${SmartRouteWebApi.serviceName}/${SmartRouteWebApi.apiVersion.latest}`;

export interface AccountGroupsModel {
    items: {
        id: string;
        name: string;
    }[];
}

export const accountGroupsApi = createApi({
    reducerPath: `${STORE_BASE_PATH}accountGroupsApi`,
    baseQuery: fetchBaseQuery({ baseUrl: ACCOUNT_GROUPS_URL, prepareHeaders }),
    endpoints: (builder) => ({
        getAccountGroups: builder.query<AccountGroupsModel, void>({
            query: (): FetchArgs => ({
                url: `${SmartRouteWebApi.endpoints.accountGroups}`,
                responseHandler: 'json',
            }),
        }),
    }),
});

export const { useGetAccountGroupsQuery, useLazyGetAccountGroupsQuery } = accountGroupsApi;
