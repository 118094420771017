import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import IframeResizer from 'iframe-resizer-react';
import { useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { DEFAULT_ROUTE } from '../../common/constants';
import { ServiceInfoActionBarItem } from '../../components/ServiceInfoActionBarItem/ServiceInfoActionBarItem';
import { config } from '../../configuration/config';
import { selectIdToken } from '../../store/tokenHandling/tokenHandlingSelectors';

interface UserMenuPlaceholderProps {
    onClick: () => void;
}

const getTourPlanningNavItem = () => ({
    key: 'tourPlanning',
    route: (
        <a href={DEFAULT_ROUTE}>
            <FormattedMessage id={'intl-msg:smartRoute.sublink.tourPlanning'} />
        </a>
    ),
});

const UserMenuPlaceholder = ({ onClick }: UserMenuPlaceholderProps) => (
    <ActionBarItem id="accountMenu">
        <div className="ActionBarItemIcon" onClick={onClick}>
            <span className="icon rioglyph rioglyph-user" />
        </div>
    </ActionBarItem>
);

const AppMenuPlaceholder = () => (
    <div className={'display-flex justify-content-center margin-25'}>
        <Spinner />
    </div>
);

export const AppLayoutHeader = () => {
    const [shouldLoadAppMenu, setShouldLoadAppMenu] = useState(false);
    const [shouldLoadUserMenu, setShouldLoadUserMenu] = useState(false);

    const idToken = useSelector(selectIdToken);

    const environment = config.id ? config.id.split('.')[1] : 'local';
    const navigationItems = [getTourPlanningNavItem()];
    const actionBarItems = [];

    useLayoutEffect(() => {
        if (shouldLoadUserMenu) {
            const getElement = () => document.querySelector('.ActionBarItemIcon[aria-describedby="userMenu"]');

            const checkForElement = () => {
                if (!getElement()) {
                    window.requestAnimationFrame(checkForElement);
                } else {
                    getElement().click();
                }
            };

            checkForElement();
        }
    }, [shouldLoadUserMenu]);

    const userMenu = shouldLoadUserMenu ? (
        <DefaultUserMenu idToken={idToken} environment={environment} />
    ) : (
        <UserMenuPlaceholder onClick={() => setShouldLoadUserMenu(true)} />
    );

    const appMenu = shouldLoadAppMenu ? (
        <IframeResizer className={'iFrameResizer'} src={config.backend.RIO_MENU} checkOrigin={false} />
    ) : (
        <AppMenuPlaceholder />
    );

    actionBarItems.push(<ServiceInfoActionBarItem />);
    actionBarItems.push(<RioNotifications />);
    actionBarItems.push(userMenu);

    return (
        <div data-testid="application-layout-header">
            <ApplicationHeader
                label={<FormattedMessage id={'intl-msg:smartRoute.moduleName'} />}
                onToggleAppMenu={() => setShouldLoadAppMenu(true)}
                appNavigator={appMenu}
                homeRoute={<a href={config.backend.HOME_URL}>{''}</a>}
                navItems={navigationItems}
                actionBarItems={actionBarItems}
            />
        </div>
    );
};
