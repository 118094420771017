import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { Outlet } from 'react-router-dom';
import { DefaultRedirect } from '../../routes/DefaultRedirect';
import { lazy } from 'react';

const ServiceInfDialog = lazy(() =>
    import('../../components/ServiceInfoDialog/ServiceInfoDialog').then(({ ServiceInfoDialog }) => ({
        default: ServiceInfoDialog,
    })),
);

export const MainContainer = () => {
    return (
        <div className={'padding-0'} data-testid="app-layout">
            <ServiceInfDialog />
            <NotificationsContainer />
            <Outlet />
            <DefaultRedirect />
        </div>
    );
};
