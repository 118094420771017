import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectCurrentCategory, selectIsSideMenuOpen } from '../../store/sideMenu/sideMenuSelectors';
import { changeCategory, SideMenuCategory, toggleSideMenu } from '../../store/sideMenu/sideMenuSlice';

export type CategoryId = string;

export const SideMenu = () => {
    const dispatch = useAppDispatch();

    const currentCategory = useAppSelector(selectCurrentCategory);
    const isSideMenuOpen = useAppSelector(selectIsSideMenuOpen);

    const handleChangeCategories = (category: SideMenuCategory) => {
        dispatch(changeCategory(category));
    };

    const handleToggleTree = () => {
        dispatch(toggleSideMenu());
    };

    return (
        <AssetTree
            className={'flex-1-0'}
            resizable
            minWidth={300}
            maxWidth={470}
            currentCategoryId={currentCategory}
            onCategoryChange={handleChangeCategories}
            isOpen={isSideMenuOpen}
            onToggleTree={handleToggleTree}
            useOffscreen
        >
            <TreeCategory
                key={SideMenuCategory.TREE_CATEGORY_ROUTE}
                id={SideMenuCategory.TREE_CATEGORY_ROUTE}
                label="Route"
                icon="rioglyph-direction"
                hasSelection={false}
            >
                Route Content
            </TreeCategory>

            <TreeCategory
                key={SideMenuCategory.TREE_CATEGORY_SEARCH}
                id={SideMenuCategory.TREE_CATEGORY_SEARCH}
                label="Search"
                icon="rioglyph-search"
                hasSelection={false}
            >
                Search Content
            </TreeCategory>
        </AssetTree>
    );
};
