import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

export const FallbackError = () => {
    return (
        <div
            className={'display-flex justify-content-center margin-top-15 margin-left-15 margin-right-15'}
            data-testid="fallback-error"
        >
            <ErrorState
                headline={'Oops, something went wrong.'}
                message={
                    <div data-testid="help-message">
                        {'If this error persists, please contact the RIO Support Hotline:'}
                        <br />
                        <span className={'text-size-large margin-top-10'}>{'00800 / 22550746'}</span>
                    </div>
                }
            />
        </div>
    );
};
