import UikitActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { FormattedMessage } from 'react-intl';
import { config } from '../../configuration/config';
import { ServiceInfoPopover } from '../ServiceInfoPopover/ServiceInfoPopover';

export const ServiceInfoActionBarItem = () => {
    const title = (
        <div>
            <FormattedMessage id={'intl-msg:smartRoute.moduleName'} />
            <span className={'text-color-gray margin-left-10'}>{config.build_version}</span>
        </div>
    );

    return (
        <UikitActionBarItem id={'serviceInfo'}>
            <UikitActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-info-sign'} data-testid={'action-bar-item-icon'} />
            </UikitActionBarItem.Icon>
            <UikitActionBarItem.Popover title={title}>
                <ServiceInfoPopover />
            </UikitActionBarItem.Popover>
        </UikitActionBarItem>
    );
};
