import packageJson from '../../package.json';

const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    backend: {
        MAP_SERVICE_API_KEY: string | undefined;
        BACKEND_DOMAIN: string | undefined;
        HOME_URL: string | undefined;
        MAP_SERVICE: string | undefined;
        POI_SERVICE: string | undefined;
        GEOFENCE_SERVICE: string | undefined;
        MARKETPLACE_SERVICE: string | undefined;
        RIO_MENU: string | undefined;
    };
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
        defaultLocale: string;
    };
    datadog: {
        datadog_logs_client_token: string;
        datadog_rum_app_id: string;
        datadof_rum_client_token: string;
    };
    id: string | undefined;
    client_id: string | undefined;
    aws_client_id: string | undefined;
    build_version: string | undefined;
    serviceName: string;
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
}

export const config: ConfigState = {
    backend: {
        MAP_SERVICE_API_KEY: import.meta.env.VITE_MAP_SERVICE_API_KEY,
        BACKEND_DOMAIN: import.meta.env.VITE_BACKEND_DOMAIN,
        HOME_URL: import.meta.env.VITE_HOME_URL,
        MAP_SERVICE: import.meta.env.VITE_MAP_SERVICE,
        POI_SERVICE: import.meta.env.VITE_POI_SERVICE,
        GEOFENCE_SERVICE: import.meta.env.VITE_GEOFENCE_SERVICE,
        MARKETPLACE_SERVICE: import.meta.env.VITE_RIO_MARKETPLACE_SERVICE,
        RIO_MENU: import.meta.env.VITE_RIO_MENU,
    },
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_CLIENT_ID,
        oauthScope: import.meta.env.VITE_AUTH_SCOPES,
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        defaultLocale: import.meta.env.VITE_DEFAULT_LOCALE,
    },
    datadog: {
        datadog_logs_client_token: import.meta.env.VITE_DATADOG_LOGS_CLIENT_TOKEN,
        datadog_rum_app_id: import.meta.env.VITE_DATADOG_RUM_APP_ID,
        datadof_rum_client_token: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
    },
    id: import.meta.env.VITE_ID,
    client_id: import.meta.env.VITE_CLIENT_ID,
    aws_client_id: import.meta.env.VITE_AWS_ACCOUNT_ID,
    build_version: import.meta.env.VITE_BUILD_VERSION,
    serviceName: packageJson.name,
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: asBool(import.meta.env.VITE_MOCK_SERVICE),
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
};
