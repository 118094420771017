import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import appReducer from '../../store/app/appSlice';
import langReducer from '../../store/lang/langSlice';
import loginReducer from '../../store/login/loginSlice';
import onboardingReducer from '../../store/onboarding/onboardingSlice';
import serviceInfoReducer from '../../store/serviceInfo/serviceInfoSlice';
import tokenReducer from '../../store/tokenHandling/tokenHandlingSlice';
import sideMenuReducer from '../../store/sideMenu/sideMenuSlice';
import { permittedAssetsApi } from '../../store/permittedAssets/permittedAssetsApi';
import { accountGroupsApi } from '../../store/accountGroups/accountGroupsApi';

const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    sideMenu: sideMenuReducer,
    onboarding: onboardingReducer,
    serviceInfo: serviceInfoReducer,
    [permittedAssetsApi.reducerPath]: permittedAssetsApi.reducer,
    [accountGroupsApi.reducerPath]: accountGroupsApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(permittedAssetsApi.middleware, accountGroupsApi.middleware),

        devTools: true,
    });
};
export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
