import { createSlice } from '@reduxjs/toolkit';

import { STORE_BASE_PATH } from '../../common/constants';
import { chapters } from '../../pages/RoutePage/chapters';

export interface serviceInfoState {
    showServiceInfoDialog: boolean;
    serviceInfoChapter: string;
}

const initialState: serviceInfoState = {
    showServiceInfoDialog: false,
    serviceInfoChapter: chapters.welcome,
};

export const serviceInfoSlice = createSlice({
    name: STORE_BASE_PATH + 'serviceInfo',
    initialState,
    reducers: {
        serviceInfoDialogClosed: (state, action) => {
            return {
                ...state,
                showServiceInfoDialog: false,
            };
        },
        serviceInfoDialogOpenedAtChapter: (state, action) => {
            return {
                showServiceInfoDialog: true,
                serviceInfoChapter: action.payload,
            };
        },
    },
});

export const { serviceInfoDialogClosed, serviceInfoDialogOpenedAtChapter } = serviceInfoSlice.actions;

export default serviceInfoSlice.reducer;
