import type { RootState } from '../../configuration/setup/store';
import { createSelector } from 'reselect';
import some from 'lodash/fp/some';

export const selectOnboarding = (state: RootState) => state.onboarding;
export const selectShowTableSettingsTip = createSelector(selectOnboarding, (state) => state.showTableSettingsTip);
export const selectShowQuickFilter = createSelector(selectOnboarding, (state) => state.showQuickFilter);
export const selectShowTree = createSelector(selectOnboarding, (state) => state.showTree);
export const selectShowShareLinkTip = createSelector(selectOnboarding, (state) => state.showShareLinkTip);
export const selectAreOnboardingTipsShown = createSelector(selectOnboarding, (state) =>
    some((item) => item === true)(state),
);
