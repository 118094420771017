import { lazy } from 'react';
import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { DEFAULT_ROUTE } from '../common/constants';
import { FallbackError } from '../components/FallbackError/FallbackError';
import { SuspendedWithSpinner } from '../components/SuspendedWithSpinner/SuspendedWithSpinner';
import { AppLayout } from '../containers/AppLayout/AppLayout';

// Lazy load pages for better performance and automatically split the bundle accordingly
const SmartRouteLazyLoaded = lazy(() =>
    import('../pages/SmartRoute/SmartRoute').then(({ SmartRoute }) => ({ default: SmartRoute })),
);

export const routes = [DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />} errorElement={<FallbackError />}>
            <Route
                path={DEFAULT_ROUTE}
                element={
                    <SuspendedWithSpinner>
                        <SmartRouteLazyLoaded />
                    </SuspendedWithSpinner>
                }
            />
        </Route>,
    ),
);
